<template>
	<div>
		<a-modal :title="getTitle" :width="850" :visible="visible"  @cancel="handleCancel" :footer="null">
			
			<a-spin :spinning="loading">
				<div class="">
					<div class="form-search-box ">
						<a-form layout="inline">
							<a-form-item label="会员昵称">
								<a-input v-model="search.nick_name" placeholder="请输入会员昵称"></a-input>
							</a-form-item>
				
							<a-form-item label="会员手机号">
								<a-input v-model="search.mobile" placeholder="请输入会员手机号"></a-input>
							</a-form-item>
				
							<a-form-item>
								<a-button @click="searchAct" type="primary">查询</a-button>
								<a-button @click="cancelAct" class="ml10">取消</a-button>
							</a-form-item>
						</a-form>
					</div>
				</div>
				
				<div class="  bg-w">
					
					<div class="wxb-table-gray mt20">
						<a-table rowKey="member_id" :columns="columns" :pagination="pagination" @change="handleTableChange" :data-source="datas"
						 :loading="loading">
							<div class="flex alcenter center" slot="vip" slot-scope="record,index">
								<img v-if="record.face!=null" :src="record.face" class="member-index-face" />
								<img v-else src="../../../../../../assets/image/woyaoyou_head_defult@2x.png" class="member-index-face" />
								<div class="ml5" style="text-align: left;">
									<div>{{record.nick_name}}</div>
									<div class="mt2">{{record.mobile}}</div>
								</div>
							</div>
							
							<div class="flex alcenter center" slot="pmember" slot-scope="pmember,record">
								{{pmember==null ? '暂无' : pmember.nick_name}}
							</div>
							
							<div class="flex alcenter center" slot="level" slot-scope="level,record">
								{{level==null ? '普通会员' : level.name}}
							</div>
							
							<div class="flex alcenter center" slot="balance" slot-scope="balance,record">
								¥{{balance}}
							</div>
							
							<!-- <div class="flex alcenter center" slot="integral" slot-scope="integral,record">
								{{integral==null ? '0' : integral.remain_integral}}
							</div> -->
					
					
							<template slot="action" slot-scope="record,index">
								<div class="flex center">
									<a-button type="primary" @click="chooseIt(record)">选择</a-button>
								</div>
							</template>
						</a-table>
					</div>
				</div>
					
			</a-spin>
		</a-modal>
	</div>
</template>

<script>
  import {listMixin} from '../../../../../../common/mixin/list.js';
	export default {
		props: {
			visible:{
				type:Boolean,
				default:false,
			},
			member_id:{
				type:Number,
				default:0
			},
		},
		mixins: [listMixin],
		data() {
			return {
				loading: false,
				confirmLoading: false,
				pagination: {
					current: 1,
					pageSize: 10, //每页中显示10条数据
					total: 0,
				},
				search: {
					nick_name: '',
					mobile: "",
				},
				columns: [
					{title: '会员编号',dataIndex: 'member_id',align: 'center'},
					{title: '会员',key: 'vip',align: 'center',scopedSlots: {customRender: 'vip'}},
					{title: '上级用户',dataIndex: 'pmember',align: 'center',scopedSlots: {customRender: 'pmember'}},
					{title: '会员等级',dataIndex: 'level',align: 'center',scopedSlots: {customRender: 'level'}},
					{title: '会员生日',dataIndex: 'birthday',align: 'center',ellipsis: true},
					{title: '账户余额',dataIndex: 'balance',align: 'center',scopedSlots: {customRender: 'balance'}},
					{title: '可用积分',dataIndex: 'integral',align: 'center',ellipsis: true},
					// {title: '累计消费',dataIndex: 'total_balance',align: 'center',sorter: true,ellipsis: true},
					{title: '操作',key: 'action',align: 'center',scopedSlots: {customRender: 'action'}}
				],
				datas: [],
			}
		},
		computed:{
			getTitle(){
				return "修改用户上级"
			}
		},
		methods: {
			getLists(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('platform/member/getMemberList',{
					nick_name:this.search.nick_name,
					mobile:this.search.mobile,
					member_id:this.member_id,
					limit:this.pagination.pageSize,
					page:this.pagination.current,
				}).then(res=>{
					this.pagination.total=res.total;
					this.datas=res.list;
					this.loading=false;
				}).catch(res=>{
					console.log(res);
					this.loading=false;
				})
			},
			
			handleTableChange(pagination, filters, sorter) {
				this.pagination.current = pagination.current;
				this.getLists();
			},
			
			/**
			 * 取消弹窗
			 */
			handleCancel() {
				this.$emit("cancel");
			},
	
			/**
			 * 提交数据
			 */
			chooseIt(record) {
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('platform/member/changePid',{
					member_id:this.member_id,
					pid:record.member_id,
					
				}).then(res=>{
					this.$message.success('修改成功',1,()=>{
						this.loading=false;
						this.$emit("ok");
					})
					
				}).catch(res=>{
					console.log(res);
					this.loading=false;
				})
			},
			
		}
	}
</script>

<style>
	
</style>
